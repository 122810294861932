import React, { memo } from 'react';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';

import { GlobalInterfaces } from '@monorepo/common';
import { useAppSelector } from '../../../store/hooks';

import { useStyles } from '../styles';

interface LeftSideBarProps extends GlobalInterfaces.IChildrenProps{
  toggleOpen:() => void
}

const LeftSideBar = ({ children, toggleOpen }:LeftSideBarProps) => {
  const classes = useStyles();
  const { isMobileMenu } = useAppSelector(({ ui }) => ({
    isMobileMenu: ui.isMobileMenu
  }));

  return (
    <Drawer
      onClose={toggleOpen}
      className={clsx(classes.drawer, {
        [classes.active]: isMobileMenu
      })}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {/* <Toolbar /> */}
      <div className={classes.drawerContainer}>
        {children}
      </div>
    </Drawer>

  );
};

export default memo(LeftSideBar);
