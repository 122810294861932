import React, { useEffect, useState } from 'react';
import { Logout } from '@mui/icons-material';
import LoginIcon from '@mui/icons-material/Login';
import cookie from 'js-cookie';
import { Button } from '@mui/material';
import { useStyles } from './styles';
import { cookieNames } from '../../constants/cookieNames';
import { baseUrls } from '../../constants/baseUrls';
import { UserBadgePanel } from '../UserBadgePanel';

// interface IHeaderRightSideProps {}
export const UserMenu = () => {
  const [isLogIn, setIsLogIn] = useState<boolean>(true);
  const classes = useStyles();

  const logOut = () => {
    window.location.reload();
    if (process.env.NODE_ENV === 'production') {
      document.cookie = 'bevnet_auth_token=; Path=/;domain=.bevnet.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    cookie.remove(cookieNames.bevnet_token);
    cookie.remove(cookieNames.refresh_token);
    setIsLogIn(true);
  };

  const redirect = () => {
    window.location.href = `${baseUrls.login}?returnUrl=${window.location.href}`;
  };
  useEffect(() => {
    if (cookie.get(cookieNames.bevnet_token)) {
      setIsLogIn(false);
      return;
    }
    setIsLogIn(true);
  }, []);

  return (
     <div className={classes.right_side}>
        {isLogIn ? (
           <Button
              onClick={redirect}
              variant="contained"
              startIcon={<LoginIcon />}
           >
              Log In
           </Button>
        ) : (
           <UserBadgePanel
              menuList={[
                {
                  title: 'Log Out',
                  icon: <Logout />,
                  handlerClick: logOut
                },
              ]}
           />
        )}
     </div>
  );
};
